<template>
  <div>
    <main-layout />
    <audio ref="audio" :src="activeSound" preload id="audio" muted></audio>
  </div>
</template>

<script>
const { io } = require("socket.io-client");
import MainLayout from "./layouts/MainLayout.vue";
import config from "./config";
export default {
  name: "App",
  // beforeCreate(){
  //   if(this.$store.state.isRTL == false){
  //     this.$store.commit('UPDATE_RTL', this.$config.isRTL)
  //     this.$vuetify.rtl = this.$config.isRTL
  //   }
  // },
  components: {
    MainLayout,
  },
  data: () => ({
    activeSound: null,
    //
  }),
  methods: {
    async getActiveSound() {
      const res = await this.$http.get(
        "/resturants/" + this.$store.state.resturant.id,
        {
          params: {
            populate: {
              selected_sound: {
                populate: '*'
              }
            },
          },
          paramsSerializer: this.$service.qsParams,
        }
      );
      if (res.data.data.selected_sound.audio) {
        console.log(res.data.data.selected_sound);
        this.activeSound = this.$service.url + res.data.data.selected_sound.audio.url
      } else {
        this.activeSound = '@/assets/sound.mp3';
      }

    },
    playNote() {
        var resp = this.$refs.audio.play();
        console.log(resp);
        if (resp !== undefined) {
          resp
            .then((_) => {
              console.log(_);
            })
            .catch((error) => {
              console.log(error);
            });
        }
    },
  },
  created() {
    if (localStorage.getItem("theme") == null) {
      localStorage.setItem("theme", JSON.stringify(config));
      window.location.reload();
    }
    localStorage.setItem("theme", JSON.stringify(config));
    const SERVER_URL = this.$service.url;
    const JWT_TOKEN = this.$store.state.userData.token;
    // if (JWT_TOKEN) {
      this.getActiveSound();
      // token will be verified, connection will be rejected if not a valid JWT
      
      // token will be verified, connection will be rejected if not a valid JWT
      const socket = io(SERVER_URL, {
        auth: {
          token: JWT_TOKEN,
        },
      });

      //  wait until socket connects before adding event listeners
      socket.on("connect", () => {
        console.log('test');
        socket.on("order:create", (data) => {
          
          console.log("new order", data);
          this.playNote();
        });
      });
      socket.emit("connection");
    // }
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css? family=Cairo:300,400,700&display=swap");
$body-font-family: "Cairo", sans-serif !important;

.v-application {
  font-family: $body-font-family;
}
</style>