import Vue from "vue";
import VueRouter from "vue-router";
// import { isElectron } from '../../utils'
// import { isUserLoggedIn } from "@/libs/auth/utils";
Vue.use(VueRouter);
const routes = [
  { path: "/", redirect: { name: "settings" } },
  {
    path: "/home",
    name: "dashboard-home",
    component: () => import("@/views/dashoard/Home.vue"),
    meta: {
      title: "الصفحة الرئيسية",
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/settings/settings.vue"),
    meta: {
      title: "المجموعات",
    },
  },
  {
    path: "/categories",
    name: "categories",
    component: () => import("@/views/categories/Categories.vue"),
    meta: {
      title: "المجموعات",
    },
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/views/products/Products.vue"),
    meta: {
      title: "المنتجات",
    },
  },
  {
    path: "/designs",
    name: "designs",
    component: () => import("@/views/designs/designs.vue"),
    meta: {
      title: "التصاميم",
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/orders/orders.vue"),
    meta: {
      title: "الطلبات",
    },
    
  },
  {
    path: "/sounds",
    name: "sounds",
    component: () => import("@/views/sounds/sounds.vue"),
    meta: {
      title: "الاصوات",
    },
  },
  {
    path: "/areas",
    name: "areas",
    component: () => import("@/views/areas/areas.vue"),
    meta: {
      title: "المناطق",
    },
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      title: "تسجيل الدخول",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  let isLogged = localStorage.getItem("accessToken") !== null;
  if (to.name !== "login" && !isLogged) {
    return next({ name: "login" });
  }
  return next();
});
export default router;
