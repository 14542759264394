export default [
  {
    title: "الصفحة الرئيسية",
    route: {
      name: "dashboard-home",
    },
    icon: "mdi-home",
  },
  {
    title: "الاعدادات",
    route: {
      name: "settings",
    },
    icon: "mdi-cog",
  },
  {
    title: "المجموعات",
    route: {
      name: "categories",
    },
    icon: "mdi-book-open-page-variant",

  },
  {
    title: "المنتجات",
    route: {
      name: "products",
    },
    icon: "mdi-spoon-sugar",

  },
  {
    title: "التصاميم",
    route: {
      name: "designs",
    },
    icon: "mdi-bookmark-box",

  },
  {
    title: "الطلبات",
    route: {
      name: "orders",
    },
    icon: "mdi-cart",

  },
  {
    title: "المناطق",
    route: {
      name: "areas",
    },
    icon: "mdi-map",

  },
  {
    title: "اصوات الاشعارات",
    route: {
      name: "sounds",
    },
    icon: "mdi-bell-alert",

  },

];
