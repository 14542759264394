const numeral = require("numeral");
const moment = require("moment");
import store from "@/store";
import qs from "qs";
export const formatNumber = (x) => {
  return numeral(x).format("0,0[.]0");
};
// export const url = "https://test.futuretech-iq.com";
export const url = "https://api.restmenu.app";
export const formatDate = (date, showTime = true, onlyTime = false) => {
  // if(onlyTime){
  //   return moment(date).format("HH:mm:ss");
  // }
  var formate = "YYYY/MM/DD";
  if (showTime) {
    formate = "YYYY/MM/DD hh:mm A";
  }
  if (onlyTime) {
    formate = "hh:mm A";
  }
  moment.locale = "ar";
  var ret = moment(date).format(formate);
  return ret;
};
export const since = (date) => {
  moment.locale = "ar";
  return moment(date).locale('ar').fromNow();
}
export const hideFrom = (roles) => {
  var role = store.state.roles[store.getters.role];
  var permissions = roles(role);
  return permissions.indexOf(false) > -1;
};
export const float = (x) => {
  return typeof x == "string" ? parseFloat(x.replace(",", "")) : x;
};
export const openUrl = (url) => {
  window.open(url, "_blank");
};
export const uploadFile = (file) => {
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("files", file);
    fetch(url + "/api/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson[0]);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const qsParams = (params) => {
  return qs.stringify(params);
};
export default {
  formatNumber,
  formatDate,
  hideFrom,
  url,
  openUrl,
  float,
  uploadFile,
  since,
  qsParams,
};
